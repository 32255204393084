import React from 'react';
import { Sidebar } from 'react-pro-sidebar';
import useCart from '../../hooks/useCart';
import ShoppingCartDetails from './ShoppingCartDetails';
import ShoppingCartHeader from './ShoppingCartHeader';
import ShoppingCartSummary from './ShoppingCartSummary';

function ShoppingCart() {
  const { allowToOrder, couponEnabled, totalItems, totalQuantity } = useCart();

  if (!allowToOrder) {
    return null;
  }

  return (
    <>
      <Sidebar collapsedWidth={0} width="100%" backgroundColor="white" defaultCollapsed="true">
        <ShoppingCartHeader />

        <ShoppingCartDetails />

        <ShoppingCartSummary totalItems={totalItems} couponEnabled={couponEnabled} checkoutEnabled={totalQuantity > 0} />
      </Sidebar>
    </>
  );
}

export default ShoppingCart;
