import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import TableSelection from './TableSelection';

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.45)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    position: 'initial',
    padding: 10,
    margin: 20,
    borderRadius: 0,
    maxWidth: 420,
    width: '100%',
  },
};

function TableSelectionModal(props) {
  const { t } = useTranslation();

  return (
    <Modal isOpen={props.isOpen} onClose={props.onHideModal} onRequestClose={props.onHideModal} style={customStyles} ariaHideApp={false}>
      <div className="table-selection-modal">
        <img className="close" src="/assets/icon/close.svg" onClick={props.onHideModal} alt={t('Close')} />
        <TableSelection />
        <div className="actions">
          <div className="button-apply" onClick={props.onHideModal}>
            {t('Ok')}
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default TableSelectionModal;
