import React from 'react';
import useMenu from '../../../hooks/useMenu';

function BusinessDetails() {
  const { description, name, backgroundURL } = useMenu();

  return (
    <div className="business-details" style={{ backgroundImage: `url(${backgroundURL})` }}>
      <div className="business-details-text">
        <h2 className="business-name">{name}</h2>
        {description && <p className="business-description">{description}</p>}
      </div>
    </div>
  );
}

export default BusinessDetails;
