import { useSelector } from 'react-redux';
import ShoppingCartService from '../services/shoppingCart';

function useOTP() {
  // Access the Redux state
  const qrcode = useSelector((state) => state.qrcode);

  const sendOTPRequest = (mobile) => {
    return ShoppingCartService.sendOTPValidation(qrcode.id, mobile);
  };
  return { sendOTPRequest };
}

export default useOTP;
