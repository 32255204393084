import { default as React } from 'react';
import { Route, Routes } from 'react-router-dom';
import { HomePage, ProductListPage, ProductDetailsPage } from '../template/TwoPageLayout';
import { OrderConfirmation, SinglePageCheckout } from '../template/Checkout';

function TwoPageLayout() {
  return (
    <Routes>
      <Route index element={<HomePage />} />
      <Route path="category/:categoryId" element={<ProductListPage />} />
      <Route path="product/:productId" element={<ProductDetailsPage />} />
      <Route path="checkout" element={<SinglePageCheckout containerClassName="two-page-layout" />} />
      <Route path="confirmation" element={<OrderConfirmation containerClassName="two-page-layout" />} />
    </Routes>
  );
}

export default TwoPageLayout;
