import { useState } from 'react';
import { useSelector } from 'react-redux';

function useMerchandisingAssociations(productId) {
  const menu = useSelector((state) => state.menu);

  const product = menu?.products?.find((p) => parseInt(p.id) == parseInt(productId));

  const [selectedAssociations, setSelectedAssociations] = useState(
    product?.merchandisingAssociations
      ?.map((a) => {
        if (parseInt(a.defaultSelected) === 1) {
          return a.productId;
        }
        return null;
      })
      .filter((a) => a != null)
  );

  const toggle = (itemId) => {
    const temp = selectedAssociations;
    if (temp.indexOf(itemId) !== -1) {
      temp.splice(temp.indexOf(itemId), 1);
    } else {
      temp.push(itemId);
    }
    setSelectedAssociations([...temp]);
  };

  const isSelected = (itemId) => {
    return selectedAssociations.indexOf(itemId) !== -1;
  };

  const getAssociationsList = (type) => {
    return product.merchandisingAssociations.filter((a) => a.type === type);
  };

  return {
    selectedAssociations,
    isEmpty: !product.merchandisingAssociations || product.merchandisingAssociations.length === 0,
    getAssociationsList,
    isSelected,
    toggle,
  };
}

export default useMerchandisingAssociations;
