import React from 'react';
import { useParams } from 'react-router-dom';
import CategoryDetails from '../../components/CategoryDetails';
import CategoryNavigation from '../../components/CategoryNavigation';
import Footer from '../../components/Footer';
import FooterNavigation from '../../components/FooterNavigation';
import Header from '../../components/Header';
import MenuFilters from '../../components/MenuFilters';
import MenuSocialLink from '../../components/MenuSocialLink';
import MiniCart from '../../components/MiniCart';
import useCart from '../../hooks/useCart';
import useMenu from '../../hooks/useMenu';
import useQRCode from '../../hooks/useQRCode';

function ProductListPage() {
  const { categoryId } = useParams();
  const { uuid } = useQRCode();
  const { allowToOrder } = useCart();

  const { name, sortEnabled, layoutSwitchEnabled, socialLinkEnabled } = useMenu();
  const { footerNavigationEnabled } = useQRCode();

  return (
    <div className="two-page-layout product-list-page" categoryid={categoryId}>
      <Header showBackButton={true} showShareButton={true} showBusinessDetails={true} backURL={'/m/' + uuid} />

      {socialLinkEnabled && <MenuSocialLink />}

      <h1 className="brand-name">{name}</h1>

      <CategoryNavigation />

      {(sortEnabled || layoutSwitchEnabled) && <MenuFilters />}

      <CategoryDetails />

      <Footer />

      {allowToOrder && <MiniCart />}

      {footerNavigationEnabled && <FooterNavigation />}
    </div>
  );
}

export default ProductListPage;
