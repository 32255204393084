import React from 'react';
import { Product } from './Product';

const IceCreamGroup = ({ products }) => {
  const lastProd = products.find((p) => ['MA1255'].indexOf(p.partnumber) != -1);
  return (
    <div>
      <div className="product-list">
        {products
          .filter((p) => ['MA1255'].indexOf(p.partnumber) === -1)
          .map((product, index) => (
            <Product key={index} product={product} />
          ))}
      </div>

      {lastProd && (
        <div className="product-list">
          <div className="product-container" product-id={lastProd.id} partnumber={lastProd.partnumber}>
            <div>
              <p className="product-name">{lastProd.name}</p>
              <p className="product-description"></p>
            </div>
            <div style={{ color: '#FFFFFF' }}>
              <p style={{ fontWeight: 'bold', fontSize: 24, margin: 0 }}>
                <span>S</span>
                <span
                  style={{
                    backgroundColor: '#fff',
                    color: '#e05c9e',
                    fontSize: 16,
                    padding: '5px 10px',
                    borderRadius: 30,
                    marginLeft: 10,
                    marginRight: 10,
                  }}>
                  ₪ 12.0
                </span>
              </p>
              <p style={{ fontWeight: 'bold', fontSize: 24, margin: 0, marginTop: 5 }}>
                L
                <span
                  style={{
                    backgroundColor: '#fff',
                    color: '#e05c9e',
                    fontSize: 16,
                    padding: '5px 10px',
                    borderRadius: 30,
                    marginLeft: 10,
                    marginRight: 10,
                  }}>
                  ₪ 15.0
                </span>
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default IceCreamGroup;
