import React from 'react';
import CategoryDetails from '../../components/CategoryDetails';
import CategoryNavigation from '../../components/CategoryNavigation';
import Footer from '../../components/Footer';
import FooterNavigation from '../../components/FooterNavigation';
import MiniCart from '../../components/MiniCart';
import useCart from '../../hooks/useCart';
import BusinessDetails from './components/BusinessDetails';

function ProductListPage() {
  const { allowToOrder } = useCart();

  return (
    <div className="test-menu-layout home-page">
      <BusinessDetails />

      <CategoryNavigation />

      <CategoryDetails />

      <Footer />

      {allowToOrder && <MiniCart />}

      <FooterNavigation showTitle={true} />
    </div>
  );
}

export default ProductListPage;
