import React from 'react';
import useMenu from '../../../hooks/useMenu';
import { Product } from './Product';

const PRICE_GROUP_LIST = ['MOCHI', 'TAIYAKI', 'TAIYAKI ICE CREAM', 'POWER MOCKTAILS', 'SAN SABSTIAN'];

const Group = ({ group }) => {
  const { getProductsInfoByIds } = useMenu();

  const products = getProductsInfoByIds(group.items);

  if (group.name === 'FISHIES') {
    return (
      <div style={{ padding: '15px' }}>
        <img
          src="https://images.getmenu.ps/accounts/6/21/category/FISHIES.jpg"
          alt="Fishies"
          style={{ display: 'block', width: '100%', borderRadius: '15px' }}
        />
        <p
          style={{
            textAlign: 'center',
            fontSize: '24px',
            fontWeight: 'bold',
            color: '#5777ba',
          }}>
          FISHIES فيشيز
        </p>
        <p
          style={{
            fontSize: '24px',
            fontWeight: 'bold',
            textAlign: 'center',
          }}>
          MIN FISH (10 PIECES): <span style={{ color: '#ea2628' }}>25.0₪</span>
        </p>

        <div
          style={{
            fontWeight: 'bold',
            fontSize: '18px',
            padding: '10px',
            border: '1px solid #000',
            position: 'relative',
          }}>
          <span
            style={{
              position: 'absolute',
              top: '-13px',
              background: 'white',
              left: 'calc(50% - 33px)',
              padding: '0 10px',
            }}>
            SAUCES
          </span>
          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <p style={{ margin: '2px', display: 'flex', alignItems: 'center', gap: 5 }}>
                <img src="https://images.getmenu.ps/accounts/6/products/UO050.jpg" width="25" />
                CUSTARD
              </p>
              <p style={{ margin: '0', color: '#ea2628' }}>5.0 ₪</p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <p style={{ margin: '2px', display: 'flex', alignItems: 'center', gap: 5 }}>
                <img src="https://images.getmenu.ps/accounts/6/products/UO048.jpg" width="25" />
                MILK CHOCOLATE
              </p>
              <p style={{ margin: '0', color: '#ea2628' }}>5.0 ₪</p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <p style={{ margin: '2px', display: 'flex', alignItems: 'center', gap: 5 }}>
                <img src="https://images.getmenu.ps/accounts/6/products/UO048.jpg" width="25" />
                DARK CHOCOLATE
              </p>
              <p style={{ margin: '0', color: '#ea2628' }}>5.0 ₪</p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <p style={{ margin: '2px', display: 'flex', alignItems: 'center', gap: 5 }}>
                <img src="https://images.getmenu.ps/accounts/6/products/UO048.jpg" width="25" />
                WHITE CHOCOLATE
              </p>
              <p style={{ margin: '0', color: '#ea2628' }}>5.0 ₪</p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <p style={{ margin: '2px', display: 'flex', alignItems: 'center', gap: 5 }}>
                <img src="https://images.getmenu.ps/accounts/6/products/UO049.jpg" width="25" />
                NUTELLA
              </p>
              <p style={{ margin: '0', color: '#ea2628' }}>5.0 ₪</p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <p style={{ margin: '2px', display: 'flex', alignItems: 'center', gap: 5 }}>
                <img src="https://images.getmenu.ps/accounts/6/products/UO052.jpg" width="25" />
                LOTUS
              </p>
              <p style={{ margin: '0', color: '#ea2628' }}>5.0 ₪</p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <p style={{ margin: '2px', display: 'flex', alignItems: 'center', gap: 5 }}>
                <img src="https://images.getmenu.ps/accounts/6/products/UO051.jpg" width="25" />
                PISTACHIO
              </p>
              <p style={{ margin: '0', color: '#ea2628' }}>5.0 ₪</p>
            </div>
          </div>
          <p style={{ textAlign: 'center', marginBottom: '0' }}>يقدم 1 صوص مع كل وجبة</p>
        </div>
      </div>
    );
  }

  const containerStyles = () => {
    if (['MOCHI', 'TAIYAKI', 'TAIYAKI ICE CREAM'].includes(group.name)) {
      return {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        fontSize: 10,
        paddingBottom: 12,
      };
    }
    return {};
  };

  return (
    <div group-id={group.id} style={{ marginBottom: 30 }}>
      <div style={{ textAlign: 'center' }}>
        <img src={group.thumbnail} alt={group.name} style={{ width: '100%', border: '1px solid lightgray', borderRadius: 10 }} />
        <div style={{ fontSize: 18, fontWeight: 600, marginTop: 15 }}>{group.name}</div>
      </div>

      {PRICE_GROUP_LIST.indexOf(group.name) !== -1 && (
        <div style={{ fontWeight: 600, padding: 20, fontSize: 20, display: 'flex', justifyContent: 'center' }}>
          {products[0]?.price} / Piece
        </div>
      )}

      <div style={{ ...containerStyles() }}>
        {products.map((product, index) => (
          <Product key={index} product={product} index={index} groupName={group.name} />
        ))}
      </div>
    </div>
  );
};

export default Group;
