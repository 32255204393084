import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PrintOrderView from '../../components/PrintOrderView';
import useCheckout from '../../hooks/useCheckout';
import OTPValidationModal from './OTPValidationModal';
import useOTP from '../../hooks/useOTP';

function OrderActions() {
  const { t } = useTranslation();
  const { sendOTPRequest } = useOTP();
  const navigate = useNavigate();

  const { printCartEnabled, otpEnabled, isValidForm, submitOrder, customerMobile } = useCheckout();
  const [otpOpen, setOtpOpen] = useState(false);
  const [otpCode, setOTPCode] = useState();

  const handleSubmitOrder = () => {
    // Valdiate Form
    if (!isValidForm()) {
      alert(t('Please fill your information'));
      return;
    }

    // If PTO enabled, validate mobile
    if (otpEnabled) {
      sendOTPRequest(customerMobile)
        .then((response) => response.json())
        .then((res) => {
          if (res.success) {
            setOTPCode(res.code);
            setOtpOpen(true);
          } else {
            alert('Unable to veiffy you mobile number, please try again later!');
          }
        });
    } else {
      sendRequest();
    }
  };

  const sendRequest = () => {
    submitOrder(
      () => {
        navigate('../confirmation');
      },
      (error) => {
        alert('Unable to submit your order right now, please try again later!');
      }
    );
  };

  let extraClass = '';
  if (printCartEnabled) {
    extraClass = 'print-enabled';
  }

  return (
    <div className={'order-actions ' + extraClass}>
      <button className="btn-submit-order" onClick={handleSubmitOrder}>
        {t('Submit Your Order')}
      </button>
      {printCartEnabled && <PrintOrderView buttonClassName="btn-print-order" buttonIcon="/assets/icon/print-solid-white.svg" />}
      {otpOpen && (
        <OTPValidationModal
          open={otpOpen}
          onSuccess={sendRequest}
          onClose={() => setOtpOpen(false)}
          mobile={customerMobile}
          validCode={otpCode}
        />
      )}
    </div>
  );
}

export default OrderActions;
