import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useQRCode from '../../hooks/useQRCode';
import Coupon from './../Coupon';
import FormatPrice from './../FormatPrice';

function ShoppingCartSummary({ totalItems, couponEnabled, checkoutEnabled }) {
  const { t } = useTranslation();
  const { uuid } = useQRCode();

  const navigate = useNavigate();

  const startCheckout = () => {
    navigate(`/m/${uuid}/checkout`);
  };

  return (
    <div className="mini-cart-summary">
      <div className="mini-cart-summary-subtotal">
        <p className="subtotal-lable">{t('Sub Total')}:</p>
        <p className="subtotal-value">
          <FormatPrice value={totalItems || 0} />
        </p>
      </div>

      {couponEnabled && <Coupon />}

      <div className="mini-cart-summary-action">
        <button className={'btn-checkout' + (checkoutEnabled ? '' : ' disabled')} onClick={startCheckout}>
          {t('Place Your Order')}
        </button>
      </div>
    </div>
  );
}

export default ShoppingCartSummary;
