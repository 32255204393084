import { bindActionCreators } from '@reduxjs/toolkit';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import OrderItemAttributes from '../../components/ShoppingCart/OrderItemAttributes';
import OrderItemAssociations from '../../components/ShoppingCart/OrderItemAssociations';
import ProductDescription from '../../components/ProductCard/ProductDescription';

function OrderItems(props) {
  const { shoppingCart, t } = props;

  const resolveShoppingCartItemProduct = (item) => {
    if (props.menu && props.menu.products) {
      return props.menu.products.find((p) => parseInt(item.productId) === parseInt(p.id));
    }
    return null;
  };

  const calculateItemPrice = (item) => {
    let total = 0;
    total += parseFloat(item.price);
    if (item.associations) {
      item.associations.forEach((ass) => {
        total += parseFloat(ass.price);
      });
    }
    return total;
  };

  return (
    <div className="order-items">
      {shoppingCart?.items?.map((item) => {
        const product = resolveShoppingCartItemProduct(item);

        return (
          <div key={item.id} className="order-item">
            <div className="order-item-name-and-description">
              <p className="order-item-name">{product?.name}</p>

              {product?.description && (
                <div className="order-item-description">
                  <ProductDescription description={product?.description} />
                </div>
              )}

              <OrderItemAttributes item={item} />

              {item.associations && item.associations.length > 0 && <OrderItemAssociations item={item} />}

              {item?.comments && (
                <p className="order-item-comments">
                  <span className="comments-title">{t('Comments')}:</span> {item.comments}
                </p>
              )}
            </div>
            <p className="order-item-details">
              <span>{item.quantity}</span> <span>x</span> <span>{calculateItemPrice(item).toFixed(2)}</span>
            </p>
          </div>
        );
      })}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    shoppingCart: state.shoppingCart,
    menu: state.menu,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(OrderItems));
