import React, { useState } from 'react';
import useMenu from '../../hooks/useMenu';

function GroupNavigation({ selectedGroup, onChange }) {
  const { selectedCategory } = useMenu();

  const handleGroupSelect = (groupId) => {
    onChange(groupId);
  };

  return (
    <div className="group-navigation">
      <div className="group-list">
        {selectedCategory &&
          selectedCategory.groups
            ?.sort((a, b) => parseFloat(a.sequence) - parseFloat(b.sequence))
            .map((group) => {
              const active = selectedGroup?.id === group.id;
              return (
                <div
                  className={'group ' + (active ? 'active' : '')}
                  group-id={group.id}
                  key={group.id}
                  onClick={() => handleGroupSelect(group.id)}>
                  {group.name}
                </div>
              );
            })}
      </div>
    </div>
  );
}

export default GroupNavigation;
