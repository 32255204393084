import React from 'react';
import { Product } from './Product';

const FshafeshGroup = ({ products }) => {
  return (
    <div>
      <div className="product-list">
        {products
          .filter((p) => p.partnumber !== 'MA1087' && p.partnumber !== 'MA1088')
          .map((product, index) => (
            <Product key={index} product={product} />
          ))}
      </div>

      <div className="product-list">
        {products
          .filter((p) => p.partnumber == 'MA1087' || p.partnumber == 'MA1088')
          .map((product, index) => (
            <Product key={index} product={product} />
          ))}
      </div>
    </div>
  );
};

export default FshafeshGroup;
