import React from 'react';
import useDesign from '../../hooks/useDesign';
import ProductCard from '../ProductCard';

function ProductList({ products }) {
  const { productListLayout } = useDesign();

  return (
    <div className={`product-list ${productListLayout}`}>
      {products.map((product) => {
        return (
          <div key={product.id} role="listitem">
            <ProductCard key={product.id} productId={product.id} />
          </div>
        );
      })}
    </div>
  );
}

export default ProductList;
