export function setMenu(menu) {
  return (dispatch) => {
    dispatch({ type: 'SET_MENU', payload: menu });
  };
}

// No longer needed
export function setProductsMap(productMap) {
  return (dispatch) => {
    dispatch({ type: 'SET_PRODUCTS_MAP', payload: productMap });
  };
}

export function resetMenu() {
  return { type: 'RESET_MENU', payload: null };
}
