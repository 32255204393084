import React from 'react';
import useMenu from '../../hooks/useMenu';
import GroupDetails from '../GroupDetails';

function CategoryDetails({ topWidget, showAllCategory = false }) {
  const { selectedCategory, activeCategories } = useMenu();

  const catList = showAllCategory ? activeCategories : [selectedCategory];

  return (
    <>
      {catList.map((cat) => (
        <div className='category-details' category-id={cat.id} key={cat.id}>
          {topWidget}

          {cat.groups &&
            cat.groups
              .sort((a, b) => a.sequence - b.sequence)
              .map((group) => {
                return <GroupDetails key={group.id} group={group} />;
              })}
        </div>
      ))}
    </>
  );
}

export default CategoryDetails;
