import { default as React } from 'react';
import { Route, Routes } from 'react-router-dom';
import Menu19LayoutTemplate from '../template/Menu19Layout';

function Menu19Layout() {
  return (
    <Routes>
      <Route index element={<Menu19LayoutTemplate />} />
      <Route path="category/:categoryId" element={<Menu19LayoutTemplate />} />
    </Routes>
  );
}

export default Menu19Layout;
