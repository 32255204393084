import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import BaseLayout from './layout/BaseLayout';
import NotFoundPage from './template/NotFoundPage';
import Loading from './components/common/Loading';

const Loader = () => <Loading />;

function App() {
  return (
    <Suspense fallback={<Loader />}>
      <main className="main">
        <Routes>
          <Route path="/m/:uuid" element={<BaseLayout />}></Route>
          <Route path="/m/:uuid/*" element={<BaseLayout />}></Route>
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </main>
    </Suspense>
  );
}

export default App;
