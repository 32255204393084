import React from 'react';
import { connect } from 'react-redux';

function FormatPrice(props) {
  const priceValue = parseFloat(props.value || 0).toFixed(props.fractionDigits != null ? props.fractionDigits : 2);
  const currency = props.config.currentCurrency;

  return (
    <span>
      {currency?.symbol} {priceValue}
    </span>
  );
}

function mapStateToProps(state) {
  return {
    config: state.config,
  };
}

export default connect(mapStateToProps)(FormatPrice);
