import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import UtilityService from '../services/utility';
import { MEDIA_BASE_URL } from '../utils/constant';

function ProductFullImage({ media, productId }) {
  return (
    <div className="product-full-image">
      {media && media.length > 1 && (
        <Carousel showStatus={false} showThumbs={false}>
          {media.map((media) => {
            return (
              <div key={media.id}>
                {media.type === 'IMAGE' && <img alt="" src={MEDIA_BASE_URL + media.path} />}
                {media.type === 'VIDEO' && (
                  <video controls>
                    <source src={MEDIA_BASE_URL + media.path} type="video/mp4" />
                  </video>
                )}
              </div>
            );
          })}
        </Carousel>
      )}
      {media && media.length === 1 && <img alt="" src={media[0].path} />}
    </div>
  );
}

export default ProductFullImage;
