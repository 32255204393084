import React from 'react';
import { useTranslation } from 'react-i18next';

function ShoppingCartEmpty() {
  const { t } = useTranslation();

  return (
    <div className="empty-cart">
      <img src="/assets/icon/cart_black.svg" alt="" />
      <h2>{t('Your cart is empty!')}</h2>
      <p>{t('Add something to enjoy your day :)')}</p>
    </div>
  );
}

export default ShoppingCartEmpty;
