import React from 'react';
import CategoryList from '../../components/CategoryList';
import Footer from '../../components/Footer';
import FooterNavigation from '../../components/FooterNavigation';
import BusinessDetails from './components/BusinessDetails';

function HomePage() {
  return (
    <div className="test-menu-layout home-page">
      <BusinessDetails />

      <CategoryList />

      <Footer />
    </div>
  );
}

export default HomePage;
