import React from 'react';
import useCart from '../../hooks/useCart';
import useProduct from '../../hooks/useProduct';
import AddToCart from '../AddToCart';
import SelectOptions from '../SelectOptions';

function ProductActions({ productId }) {
  const { allowToOrder } = useCart();

  const { availableItems, merchandisingAssociations, type, attributes } = useProduct(productId);

  const RenderCTAButton = () => {
    let productHaveOptions = false;
    let addToCartProductId = productId;

    if (type === 'COMPLEX_PRODUCT' && availableItems.length > 1) {
      productHaveOptions = true;
    } else if (type === 'COMPLEX_PRODUCT' && availableItems.length === 1) {
      addToCartProductId = availableItems[0].id;
    }

    if (merchandisingAssociations && merchandisingAssociations.length > 0) {
      productHaveOptions = true;
    }

    if (attributes && attributes.filter((a) => parseInt(a.usage) === 3).length > 0) {
      productHaveOptions = true;
    }
    if (productHaveOptions) {
      return <SelectOptions productId={productId} />;
    }

    return <AddToCart productId={addToCartProductId} />;
  };

  if (!allowToOrder) {
    return null;
  }

  return (
    <div className="product-actions">
      <RenderCTAButton />
    </div>
  );
}

export default ProductActions;
