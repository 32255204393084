import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import useProduct from '../../hooks/useProduct';
import AddToCart from '../AddToCart';
import ProductName from '../ProductCard/ProductName';
import ProductDefiningAttribute from '../ProductDefiningAttribute';
import ProductPrice from '../ProductPrice';
import useMenu from '../../hooks/useMenu';
import ProductDescription from '../ProductCard/ProductDescription';
import MerchandisingAssociations from '../ProductDetails/MerchandisingAssociations';
import ProductOptionAttribute from '../ProductOptionAttribute';

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.45)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    position: 'initial',
    padding: 0,
    margin: 20,
    borderRadius: 0,
    maxWidth: 420,
    width: '100%',
  },
};

function QuickViewModal({ open, onClose, productId }) {
  const { t } = useTranslation();
  const { name, description, resolveSelectedItem, type } = useProduct(productId);
  const [selectedAttributes, setSelectedAttributes] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const { id } = useMenu();
  const [selectedAssociations, setSelectedAssociations] = useState([]);

  useEffect(() => {
    const selectedItem = resolveSelectedItem(selectedAttributes);
    if (selectedItem) {
      setSelectedItemId(selectedItem.id);
    } else {
      setSelectedItemId(null);
    }
  }, [selectedAttributes]);

  return (
    <Modal isOpen={open} onRequestClose={onClose} style={customStyles} ariaHideApp={false}>
      <div className={`quick-view-modal menu-${id}`} product-id={productId}>
        <img className="close" src="/assets/icon/close.svg" onClick={onClose} alt={t('Close')} />
        <div className="quick-view-body">
          <ProductName name={name} productId={productId} />
          <ProductDescription description={description} />

          <ProductPrice productId={productId} selectedItemId={selectedItemId} />

          <ProductDefiningAttribute productId={productId} onChange={setSelectedAttributes} />

          <ProductOptionAttribute productId={productId} onChange={setSelectedOptions} />

          <MerchandisingAssociations onChange={setSelectedAssociations} productId={productId} />
        </div>

        <div className="actions">
          <AddToCart
            showNote={true}
            productId={type === 'COMPLEX_PRODUCT' ? selectedItemId : productId}
            merchandisingAssociations={selectedAssociations}
            attributes={selectedOptions}
            onSuccess={onClose}
          />
        </div>
      </div>
    </Modal>
  );
}

export default QuickViewModal;
