import React from 'react';
import CategoryList from '../../components/CategoryList';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import MenuSocialLink from '../../components/MenuSocialLink';
import useMenu from '../../hooks/useMenu';

function HomePage() {
  const { description, name } = useMenu();

  return (
    <div className="masteck-ananas-template home-page  main-card">
      <Header showBackButton={false} showShareButton={true} showBusinessDetails={true} />

      <MenuSocialLink />

      <div className="business-details">
        <div className="business-details-text">
          <h2>{name}</h2>
          {description && <p className="paragraph-large">{description}</p>}
        </div>
      </div>

      <CategoryList />

      <Footer />
    </div>
  );
}

export default HomePage;
