import { default as React } from 'react';
import { Route, Routes } from 'react-router-dom';
import SinglePageLayoutTemplate from '../template/SinglePageLayout';

function SinglePageLayout() {
  return (
    <Routes>
      <Route index element={<SinglePageLayoutTemplate />} />
      <Route path="category/:categoryId" element={<SinglePageLayoutTemplate />} />
    </Routes>
  );
}

export default SinglePageLayout;
