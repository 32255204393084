import React from 'react';
import CategoryDetails from '../../components/CategoryDetails';
import CategoryNavigation from '../../components/CategoryNavigation';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import useQRCode from '../../hooks/useQRCode';
import useDesign from '../../hooks/useDesign';

function SinglePageLayoutTemplate() {
  const { uuid } = useQRCode();

  const { showAllCategory } = useDesign();

  return (
    <div className='single-page-layout main-card'>
      <Header showBackButton={false} showShareButton={true} showBusinessDetails={true} backURL={'/m/' + uuid} />
      <div className='body'>
        <CategoryNavigation showAllCategory={showAllCategory} />

        <CategoryDetails showAllCategory={showAllCategory} />
      </div>
      <Footer />
    </div>
  );
}

export default SinglePageLayoutTemplate;
