import React from 'react';

function ProductDescription({ description, title = null }) {
  const isHtml = () => {
    const htmlPattern = /<\/?[a-z][\s\S]*>/i; // A simple regex to check for HTML tags
    return htmlPattern.test(description);
  };

  return (
    <div className="product-description">
      {title && <p className="product-description-title">{title}</p>}
      {isHtml() ? (
        <div className="product-description-value" dangerouslySetInnerHTML={{ __html: description }}></div>
      ) : (
        <p className="product-description-value">{description}</p>
      )}
    </div>
  );
}

export default ProductDescription;
