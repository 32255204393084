import React from 'react';
import { useTranslation } from 'react-i18next';
import StarRatings from 'react-star-ratings';

function ProductRate({ rate, totalView }) {
  const { t } = useTranslation();

  return (
    <div className="product-rating">
      <StarRatings
        rating={parseFloat(rate || 0)}
        starDimension="15px"
        starSpacing="5px"
        starRatedColor="#5777ba"
        numberOfStars={1}
        name="rating"
      />
      <span>
        {parseFloat(rate || 0).toFixed(1)} / 5.0 ({parseInt(totalView || 0)} {t('reviews')})
      </span>
    </div>
  );
}

export default ProductRate;
