import { useSelector } from 'react-redux';
import { MEDIA_BASE_URL } from '../utils/constant';
import { useParams } from 'react-router-dom';
import useLocalization from './useLocalization';

function useMenu() {
  // Access the Redux state
  const menu = useSelector((state) => state.menu);
  const { categoryId: selectedCategoryId } = useParams();
  const { currentCurrency } = useLocalization();

  const diningOptionsModalEnabled = menu?.settings?.DINE_IN_TAKEAWAY_MODAL_ENABLED === 'true';
  const socialLinkEnabled = menu?.settings?.SOCIAL_LINK_ENABLED === 'true';
  const sortEnabled = menu?.settings?.SORT_ENABLED === 'true';
  const layoutSwitchEnabled = menu?.settings?.LAYOUT_SWITCH_ENABLED === 'true';
  const displaySelectedCategoryNameEnabled = menu?.settings?.DISPLATY_SELECTED_CATEGORY_NAME === 'true';
  const categoryNavigationType = menu?.settings?.CATEGORY_NAVIGATION_TYPE || 'TEXT';
  const dineInExtraCostEnabled = menu?.settings?.DINE_IN_EXTRA_COST_ENABLED === 'true';

  const mainCategoryListVersion = menu?.settings?.MAIN_CATEGORY_LIST_VERSION === 'true';
  const showMainCategoryTitle = menu?.settings?.SHOW_MAIN_CATEGORY_TTILE === 'true';
  const showBrowseAllMenuButton = menu?.settings?.SHOW_BROWSE_ALL_MENU_BUTTON === 'true';
  const shareLinkEnabled = menu?.settings?.SHARE_LINK_ENABLED === 'true';
  const activeOrdersEnabled = menu?.settings?.ACTIVE_ORDERS_ENABLED === 'true';
  const reviewEnabled = menu?.settings?.REVIEW_ENABLED === 'true';
  const showRangePrice = menu?.settings?.SHOW_RANGE_PRICE === 'true';
  const hideZeroPriceForIngredients = menu?.settings?.HIDE_ZERO_PRICE_FOR_INGREDIENTS === 'true';

  let productListVersion = 1;
  if (menu && menu.settings) {
    productListVersion = parseInt(menu.settings.PLP_VERSION || 1);
  }

  const activeCategories = menu?.categories
    ?.filter((c) => {
      return c.name !== null && c.name !== '' && parseInt(c.hidden_category) !== 1;
    })
    .sort((a, b) => a.sequence - b.sequence);

  const mainCategories = menu?.categories
    ?.filter((c) => parseInt(c.main_category) === 1 && parseInt(c.hidden_category) !== 1)
    .sort((a, b) => a.sequence - b.sequence);

  let selectedCategory = menu?.categories?.find((cat) => cat.id == selectedCategoryId);

  if (!selectedCategory && activeCategories) {
    selectedCategory = activeCategories[0];
  }

  const getProductById = (id) => {
    return menu?.products.find((p) => parseInt(p.id) === parseInt(id));
  };

  const getProductsInfoByIds = (ids) => {
    if (!ids) {
      return [];
    }
    const products = menu?.products
      ?.filter((p) => ids.indexOf(p.id) !== -1)
      .map((p) => {
        return {
          id: p.id,
          name: p.name,
          description: p.description,
          partnumber: p.partnumber,
          price: p['price_' + currentCurrency.id],
          thumbnail: p.thumbnail ? p.thumbnail : p.media?.[0]?.path,
        };
      });

    return products.sort((a, b) => ids.indexOf(a.id) - ids.indexOf(b.id));
  };

  const getCategoryById = (id) => {
    return menu?.categories?.find((c) => parseInt(c.id) === parseInt(id));
  };

  return {
    ...menu,
    diningOptionsModalEnabled,
    productListVersion,
    socialLinkEnabled,
    sortEnabled,
    layoutSwitchEnabled,
    logoURL: resolveMenuLogo(menu.logo),
    faviconURL: resolveMenuFavicon(menu.favicon),
    backgroundURL: resolveMenuBackground(menu.background),
    activeCategories,
    mainCategories,
    selectedCategory: selectedCategory,
    displaySelectedCategoryNameEnabled,
    categoryNavigationType,
    showMainCategoryTitle,
    mainCategoryListVersion,
    showBrowseAllMenuButton,
    dineInExtraCostEnabled,
    shareLinkEnabled,
    activeOrdersEnabled,
    reviewEnabled,
    showRangePrice,
    hideZeroPriceForIngredients,
    getProductsInfoByIds: getProductsInfoByIds,
    getProductById: getProductById,
    getCategoryById: getCategoryById,
  };
}

export default useMenu;

/**
 * @param {string} logo
 * @returns Menu logo path
 */
function resolveMenuLogo(logo) {
  let tmp = null;
  if (logo) {
    tmp = logo;
    if (tmp && !tmp.startsWith('http')) {
      tmp = MEDIA_BASE_URL + tmp;
    }
  }
  return tmp;
}

/**
 *
 * @param {string} favicon
 * @returns
 */
const resolveMenuFavicon = (favicon) => {
  let tmp = '/favicon.icon';
  if (favicon) {
    tmp = favicon;
    if (tmp && !tmp.startsWith('http')) {
      tmp = MEDIA_BASE_URL + tmp;
    }
  }
  return tmp;
};

/**
 *
 * @param {string} background
 * @returns
 */
const resolveMenuBackground = (background) => {
  let tmp = null;
  if (background) {
    tmp = background;
    if (tmp && !tmp.startsWith('http')) {
      tmp = MEDIA_BASE_URL + tmp;
    }
  }
  return tmp;
};
