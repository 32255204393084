import React from 'react';
import ItemIcon from '../components/ItemIcon';
import ItemPrice from '../components/ItemPrice';
import ItemThumbnail from '../components/ItemThumbnail';

export const Product = ({ product, groupName, index }) => {
  if (groupName === 'MOCHI' || groupName === 'TAIYAKI' || groupName === 'TAIYAKI ICE CREAM') {
    return <ItemThumbnail key={product.id} item={product} index={index} />;
  } else if (groupName === 'CHEESECAKE') {
    return <ItemPrice key={product.id} item={product} index={index} showThumbnail={true} />;
  } else if (groupName === 'POWER MOCKTAILS' || groupName === 'SAN SABSTIAN') {
    return <ItemIcon key={product.id} item={product} index={index} />;
  } else if (
    groupName === 'HOT DRINKS' ||
    groupName === 'HOT CHOCOLATE' ||
    groupName === 'COLD COFFEE' ||
    groupName === 'SMOOTHIES' ||
    groupName === 'YOGURT'
  ) {
    return <ItemPrice key={product?.id} item={product} index={index} showThumbnail={false} />;
  } else {
    return <ItemPrice key={product?.id} item={product} index={index} showThumbnail={true} />;
  }
};
