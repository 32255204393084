import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useQRCode from '../hooks/useQRCode';
import useDesign from '../hooks/useDesign';
import QuickViewModal from './QuickViewModal';

function SelectOptions({ productId }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { uuid } = useQRCode();

  const { quickViewEnabled } = useDesign();

  const [quickViewOpen, setQuickViewOpen] = useState(false);

  const navigateToProduct = () => {
    if (quickViewEnabled) {
      // Open Quick View Modal
      setQuickViewOpen(true);
    } else {
      // Open PDP
      navigate(`/m/${uuid}/product/${productId}`);
    }
  };

  return (
    <>
      <button className="btn-add-to-cart" onClick={navigateToProduct}>
        {t('Select Options ...')}
      </button>

      {quickViewOpen && <QuickViewModal open={quickViewOpen} productId={productId} onClose={() => setQuickViewOpen(false)} />}
    </>
  );
}

export default SelectOptions;
