import React from 'react';
import { Product } from './Product';

const WafflesGroup = ({ products }) => {
  return (
    <div>
      <div className="product-list">
        {products
          .filter((p) => ['MA1034', 'MA1035', 'MA1036', 'MA1037', 'MA1184'].indexOf(p.partnumber) === -1)
          .map((product, index) => (
            <Product key={index} product={product} />
          ))}
      </div>

      <div className="product-list">
        {products
          .filter((p) => ['MA1034', 'MA1035', 'MA1036', 'MA1037'].indexOf(p.partnumber) != -1)
          .map((product, index) => (
            <Product key={index} product={product} />
          ))}
      </div>
      <div className="product-list">
        {products
          .filter((p) => p.partnumber === 'MA1184')
          .map((product, index) => (
            <Product key={index} product={product} />
          ))}
      </div>
    </div>
  );
};

export default WafflesGroup;
