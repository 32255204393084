import React from 'react';
import useMenu from '../../hooks/useMenu';
import useProduct from '../../hooks/useProduct';
import ProductCard from '../../components/ProductCard';

function MenuGroup({ selectedGroupId }) {
  const { selectedCategory } = useMenu();
  const { getProductById } = useProduct();

  return (
    <div className="menu-group-list">
      {selectedCategory &&
        selectedCategory.groups &&
        selectedCategory.groups
          .sort((a, b) => a.sequence - b.sequence)
          .map((group) => {
            return (
              <div
                className="menu-group"
                key={group.id}
                data-group-id={group.id}
                style={{ display: selectedGroupId == group.id ? 'block' : 'none' }}>
                <div className="group-title">{group.name}</div>

                {group.description && <div className="group-description">{group.description}</div>}

                <div className="menu-group-items">
                  <div className={'items-list large-grid-layout'}>
                    {group.items &&
                      group.items.map((item) => {
                        const product = getProductById(item);
                        return <ProductCard key={product.id} productId={product.id}></ProductCard>;
                      })}
                  </div>
                </div>
              </div>
            );
          })}
    </div>
  );
}

export default MenuGroup;
