import { default as React } from 'react';
import { Route, Routes } from 'react-router-dom';
import { HomePage, ProductListPage } from '../template/MastekAnanas';

function MastekAnanasLayout() {
  return (
    <Routes>
      <Route index element={<HomePage />} />
      <Route path="category/:categoryId" element={<ProductListPage />} />
    </Routes>
  );
}

export default MastekAnanasLayout;
