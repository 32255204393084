import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function OrderConfirmation() {
  const { t } = useTranslation();

  return (
    <div className="section order-confirmation-container">
      <img alt="" className="order-confirmation-icon" src="/assets/icon/confirmation.svg" />
      <div className="order-confirmation-details">
        <p className="order-successfully">{t('Your Order Is Confirmed')}</p>
        <p className="thank-you">{t('Thank you!')}</p>
        <p className="tag-message">{t('ORDER_HINT')}</p>
        <div className="back-link">
          <Link to="../">{t('BACK TO OUR MENU')}</Link>
        </div>
      </div>
    </div>
  );
}

export default OrderConfirmation;
