import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useProSidebar } from 'react-pro-sidebar';
import useCart from '../../hooks/useCart';
import useConfig from '../../hooks/useConfig';
import { fetchShoppingCartDetails } from '../../actions/shoppingCartActions';
import ShoppingCartService from '../../services/shoppingCart';
import TableSelectionModal from '../TableSelectionModal';
import useQRCode from '../../hooks/useQRCode';
import { useDispatch } from 'react-redux';

function AddToCart({ merchandisingAssociations, attributes, productId, showNote, onSuccess }) {
  const { allowToOrder, diningOption, autoCartOpen, tableNumber, addItemNoteEnabled, showTableSelectionModalOnFirstItem } = useCart();
  const { currentCurrency } = useConfig();
  const [isModalVisible, setModalVisible] = useState(false);
  const { id: qrcodeId } = useQRCode();
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const [text, setText] = useState(t('Add To Cart'));
  const [comments, setComments] = useState(t(''));
  const { collapseSidebar } = useProSidebar();

  const addProductToCart = () => {
    if (showTableSelectionModalOnFirstItem && (!tableNumber || parseInt(tableNumber) < 1)) {
      setModalVisible(true);
    } else {
      setText(t('Add To Cart ...'));
      sendAddToCartRequest();
    }
  };

  const sendAddToCartRequest = () => {
    ShoppingCartService.addProductToCart(
      qrcodeId,
      productId,
      1,
      currentCurrency.id,
      diningOption,
      merchandisingAssociations,
      attributes,
      comments,
      tableNumber
    )
      .then((response) => response.json())
      .then((data) => {
        dispatch(fetchShoppingCartDetails(qrcodeId));
        setText(t('Add To Cart'));
        if (autoCartOpen) {
          collapseSidebar();
        }
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  if (!allowToOrder) {
    return null;
  }

  return (
    <>
      {showNote && addItemNoteEnabled && (
        <div className="item-comments">
          <p className="item-comments-title">{t('Notes')}:</p>
          <textarea
            rows="3"
            placeholder={t('Add Your Comments...')}
            value={comments}
            onChange={(event) => setComments(event.target.value)}></textarea>
        </div>
      )}
      {showTableSelectionModalOnFirstItem && <TableSelectionModal isOpen={isModalVisible} onHideModal={() => setModalVisible(false)} />}
      <button className="btn-add-to-cart" disabled={!productId} onClick={addProductToCart}>
        {t(text)}
      </button>
    </>
  );
}

export default AddToCart;
