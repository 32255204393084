import React from 'react';
import { Product } from './Product';

const YogurtGroup = ({ products }) => {
  const groups = [
    ['MA1123', 'MA1124', 'MA1125'],
    ['MA1135', 'MA1136', 'MA1137', 'MA1138', 'MA1139', 'MA1140', 'MA1141'],
  ];

  return (
    <div>
      <div className="product-list">
        {products
          .filter((p) => groups[0].indexOf(p.partnumber) != -1)
          .map((product, index) => (
            <Product key={index} product={product} />
          ))}
      </div>

      <div className="product-list">
        {products
          .filter((p) => groups[1].indexOf(p.partnumber) === -1 && groups[0].indexOf(p.partnumber) === -1)
          .map((product, index) => (
            <Product key={index} product={product} />
          ))}
      </div>

      <div className="product-list">
        {products
          .filter((p) => groups[1].indexOf(p.partnumber) != -1)
          .map((product, index) => (
            <Product key={index} product={product} />
          ))}
      </div>
    </div>
  );
};

export default YogurtGroup;
