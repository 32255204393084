const API_BASE_URL = process.env.REACT_APP_API_URL;

export const GET_MENU_JSON_FILE_URL = process.env.REACT_APP_MENU_CACHE_FILES_BASE_URL + '/menu/{menuId}_{langId}.json?version={version}';

export const GET_QR_CODE_URL = API_BASE_URL + '/public/qrcode/{uuid}';

export const GET_SHOPPING_CART_SUMMARY_URL = API_BASE_URL + '/public/qrcode/{qrcodeId}/cart/summary';

export const GET_SHOPPING_CART_DETAILS_URL = API_BASE_URL + '/public/qrcode/{qrcodeId}/cart/details';

export const LOAD_SHOPPING_CART_URL = API_BASE_URL + '/public/qrcode/{qrcodeId}/cart/load';

export const GET_SHOPPING_CART_LIST_URL = API_BASE_URL + '/public/qrcode/{qrcodeId}/cart/list';

export const ADD_PRODUCT_TO_CART_URL = API_BASE_URL + '/public/qrcode/{qrcodeId}/cart/item/add';

export const UPDATE_SHOPPING_CART_URL = API_BASE_URL + '/public/qrcode/{qrcodeId}/cart/update';

export const REMOVE_ITEM_FROM_CART_URL = API_BASE_URL + '/public/qrcode/{qrcodeId}/cart/item/{itemId}/remove';

export const UPDATE_CART_ITEM_URL = API_BASE_URL + '/public/qrcode/{qrcodeId}/cart/item/update';

export const SEND_PRODUCT_REVIEW_URL = API_BASE_URL + '/public/reviews/submit';

export const GET_PRODUCT_REVIEWS_URL = API_BASE_URL + '/public/reviews/byId/{productId}';

export const SUBMIT_CART_URL = API_BASE_URL + '/public/qrcode/{qrcodeId}/cart/submit';

export const CART_MOBILE_VERIFICATION_URL = API_BASE_URL + '/public/qrcode/{qrcodeId}/cart/mobile-verification';

export const TRACK_EVENT_URL = API_BASE_URL + '/public/event';

export const UPDATE_ORDER_URL = API_BASE_URL + '/public/qrcode/{qrcodeId}/cart/update-order';
