import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useMerchandisingAssociations from '../../../hooks/useMerchandisingAssociations';
import AssociationsList from './AssociationsList';

function MerchandisingAssociations({ productId, onChange }) {
  const { t } = useTranslation();

  const { isEmpty, getAssociationsList, toggle, isSelected, selectedAssociations } = useMerchandisingAssociations(productId);

  useEffect(() => {
    if (onChange) {
      onChange(selectedAssociations);
    }
  }, [selectedAssociations]);

  // If no merchandising associations, don't render anything
  if (isEmpty) {
    return null;
  } else {
    const additions = getAssociationsList('ADDITIONS');
    const sauce = getAssociationsList('SAUCE');
    const ingredients = getAssociationsList('INGREDIENTS');
    const options = getAssociationsList('WITHOUT');

    return (
      <div className="merchandising-associations">
        <AssociationsList onSelect={toggle} isSelected={isSelected} list={ingredients} type="INGREDIENTS" title={t('Ingredients')} />

        <AssociationsList onSelect={toggle} isSelected={isSelected} list={additions} type="ADDITIONS" title={t('Additions')} />

        <AssociationsList onSelect={toggle} isSelected={isSelected} list={options} type="OPTIONS" title={t('Options')} />

        <AssociationsList onSelect={toggle} isSelected={isSelected} list={sauce} type="SAUCE" title={t('Sauce For Choice')} />
      </div>
    );
  }
}

export default MerchandisingAssociations;
