import { useSelector } from 'react-redux';

function useDesign() {
  // Access the Redux state
  const qrcode = useSelector((state) => state.qrcode);

  const settings = {};

  if (qrcode && qrcode.settings) {
    settings.categoryListType = qrcode.settings.categoryListType || 'ListView';
    settings.categoryNavigationType = qrcode.settings.categoryNavigationType || 'TEXT';
    settings.displaySelectedCategoryNameEnabled = qrcode.settings.displaySelectedCategoryNameEnabled === 'true';
    settings.productCardVersion = parseInt(qrcode.settings.productCardVersion) || 1;
    settings.menuTemplate = qrcode.settings.menuTemplate || 'SinglePageLayout';
    settings.showCategoryDescription = qrcode.settings.showCategoryDescription === 'true';
    // Product List Layout: list-view, small-grid-view, large-grid-view, stack-view
    settings.productListLayout = qrcode.settings.productListLayout || 'list-view';
    settings.pdpEnabled = qrcode.settings.pdpEnabled === 'true';
    settings.quickViewEnabled = qrcode.settings.quickViewEnabled === 'true';
    settings.showAllCategory = qrcode.settings.showAllCategory === 'true';
  }

  return {
    ...settings,
  };
}

export default useDesign;
