import React from 'react';
import HomePageLink from './HomePageLink';
import LocalSwitch from './LocalSwitch';
import ShoppingCart from './ShoppingCart';

function FooterNavigation({ showTitle = false, iconSize }) {
  return (
    <div className="footer-navigation">
      <ShoppingCart showTitle={showTitle} iconSize={iconSize} />

      <HomePageLink showTitle={showTitle} iconSize={iconSize} />

      <LocalSwitch showTitle={showTitle} iconSize={iconSize} />
    </div>
  );
}

export default FooterNavigation;
