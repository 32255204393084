import React from 'react';
import CategoryDetails from '../../components/CategoryDetails';
import CategoryNavigation from '../../components/CategoryNavigation';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import MenuSocialLink from '../../components/MenuSocialLink';
import useMenu from '../../hooks/useMenu';
import useQRCode from '../../hooks/useQRCode';

function MexicanRollLayoutTemplate() {
  const { uuid } = useQRCode();
  const { name } = useMenu();

  return (
    <div className="single-page-layout mexican-roll-template main-card">
      <Header showBackButton={false} showShareButton={false} showBusinessDetails={true} backURL={'/m/' + uuid} />

      <div className="body">
        <MenuSocialLink containerStyle={{ marginTop: 15 }} />

        <h1 class="brand-name">{name}</h1>

        <CategoryNavigation />

        <CategoryDetails />
      </div>
      <Footer />
    </div>
  );
}

export default MexicanRollLayoutTemplate;
