import React, { useEffect } from 'react';
import TableSelection from '../../components/TableSelection';
import useCheckout from '../../hooks/useCheckout';
import CustomerInfo from './CustomerInfo';
import OrderActions from './OrderActions';
import OrderComments from './OrderComments';
import OrderHeader from './OrderHeader';
import OrderItems from './OrderItems';
import OrderPayments from './OrderPayments';
import OrderSummary from './OrderSummary';

function SinglePageCheckout({ containerClassName }) {
  const { customerFormEnabled, tableSelectionEnabled } = useCheckout();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={`section checkout-container ${containerClassName}`}>
      <OrderHeader />
      <OrderItems />
      <OrderSummary />
      <OrderPayments />
      {customerFormEnabled && <CustomerInfo />}
      {tableSelectionEnabled && <TableSelection />}
      <OrderComments />
      <OrderActions />
    </div>
  );
}

export default SinglePageCheckout;
