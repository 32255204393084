import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import useMenu from '../../hooks/useMenu';
import useQRCode from '../../hooks/useQRCode';
import LocalSwitchModal from '../LocalSwitchModal';
import ShareModal from './../ShareModal';
import useConfig from '../../hooks/useConfig';
import LanguageSwitch from '../LanguageSwitch';

function Header(props) {
  const { logoURL, backgroundURL, shareLinkEnabled, activeOrdersEnabled } = useMenu();
  const { uuid, showLocalSwitch, showLanguageSwitch } = useQRCode();
  const { t } = useTranslation();
  const { currentCurrency, currentLanguage } = useConfig();
  const [open, setOpen] = useState(false);

  const activeOrdersUrl = '/m/' + uuid + '/orders';
  const showTopAction =
    props.showBackButton || (props.showShareButton && shareLinkEnabled) || activeOrdersEnabled || showLocalSwitch || showLanguageSwitch;

  const renderLocalSwitch = () => {
    if (showLocalSwitch) {
      return (
        <LocalSwitchModal open={open} onClose={() => setOpen(false)}>
          <button className="local-switch" onClick={() => setOpen(true)}>
            {currentLanguage?.title} ({currentCurrency?.symbol})
          </button>
        </LocalSwitchModal>
      );
    }
    if (showLanguageSwitch) {
      return <LanguageSwitch />;
    }

    return null;
  };

  return (
    <div className="top-header">
      {showTopAction && (
        <div className="top-actions">
          <div className="left">
            {props.showBackButton && (
              <div className="action back">
                <Link to={props.backURL}>
                  <img src="/assets/icon/primary-arrow.svg" alt="" />
                </Link>
              </div>
            )}
            {props.showShareButton && shareLinkEnabled && (
              <div className="action share">
                <ShareModal />
              </div>
            )}
          </div>
          <div className="right">
            {activeOrdersEnabled && (
              <Link to={activeOrdersUrl} className="active-orders-button">
                {t('Orders')}
                <img src="/assets/icon/cart-shopping-solid.svg" alt="" />
              </Link>
            )}
            {renderLocalSwitch()}
          </div>
        </div>
      )}
      {props.showBusinessDetails && (
        <>
          {backgroundURL && (
            <div className="image-wrapper">
              <img alt="" src={backgroundURL} className="image" />
            </div>
          )}
          {logoURL && (
            <div className="container">
              <div className="business-image">
                <img alt="" src={logoURL} />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default Header;
