import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import useMenu from '../../hooks/useMenu';

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.45)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    position: 'initial',
    padding: 0,
    margin: 20,
    borderRadius: 0,
    maxWidth: 420,
    width: '100%',
  },
};

function OTPValidationModal({ open, onClose, onSuccess, mobile, validCode }) {
  const { t } = useTranslation();
  const [code, setCode] = useState();
  const [error, setError] = useState('');
  const { id: menuId } = useMenu();

  const validateOTPCode = () => {
    setError('');
    if (validCode === code) {
      onSuccess();
    } else {
      setCode('');
      setError(t('Invalid Verification Code!'));
    }
  };

  return (
    <Modal isOpen={open} onRequestClose={onClose} style={customStyles} ariaHideApp={false}>
      <div className={`otp-validation-modal menu-${menuId}`}>
        <img className="close" src="/assets/icon/close.svg" onClick={onClose} alt={t('Close')} />
        <div className="otp-validation-body">
          <h4 className="otp-validation-title">{t('Mobile Number Verification')}</h4>
          <p className="otp-validation-hint">{t('MOBILE_VERIFICATION_HINT').replace('{mobile}', mobile)}</p>
          <input maxLength={4} pattern="\d{4}" placeholder={t('XXXX')} value={code} onChange={(e) => setCode(e.target.value)} />
        </div>
        {error && error !== '' && <p className="otp-validation-error">{error}</p>}
        <div className="actions">
          <button className="btn-submit" disabled={!code || code.length !== 4} onClick={validateOTPCode}>
            {t('Validate')}
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default OTPValidationModal;
