import React from 'react';
import { Product } from './Product';
import useMenu from '../../hooks/useMenu';
import FormatPrice from '../../components/FormatPrice';
import usePrice from '../../hooks/usePrice';

const MojitoGroup = ({ products }) => {
  const { id: menuId } = useMenu();

  const redBullProduct = products.find((p) => p.partnumber === 'MA1122');

  const baseProduct = products.filter(
    (p) =>
      [
        'MA1095',
        'MA1096',
        'MA1097',
        'MA1098',
        'MA1099',
        'MA1100',
        'MA1101',
        'MA1102',
        'MA1103',
        'MA1104',
        'MA1105',
        'MA1106',
        'MA1107',
        'MA1108',
        'MA1109',
        'MA110',
        'MA1111',
      ].indexOf(p.partnumber) !== -1
  );

  const addedProduct = products.filter(
    (p) => ['MA1112', 'MA1113', 'MA1114', 'MA1115', 'MA1116', 'MA1118', 'MA1119', 'MA1120', 'MA1121'].indexOf(p.partnumber) !== -1
  );

  const { price: basePrice, extraCost: baseExtraCost } = usePrice(baseProduct[0]?.id);

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }} className="top-list">
        <div className="product-list" style={{ border: '1px dashed #ffffff' }}>
          {baseProduct.map((product, index) => (
            <Product key={index} product={product} />
          ))}
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <p className="products-price">
            <FormatPrice value={basePrice + baseExtraCost} fractionDigits={1} />
          </p>
          <div className="product-list" style={{ border: '1px dashed #ffffff' }}>
            {addedProduct.map((product, index) => (
              <Product key={index} product={product} />
            ))}
          </div>
        </div>
      </div>

      {redBullProduct && (
        <div className="product-list" style={{ border: '1px dashed #ffffff' }}>
          <Product product={redBullProduct} />
        </div>
      )}
    </>
  );
};

export default MojitoGroup;
