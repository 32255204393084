import { MEDIA_BASE_URL } from '../utils/constant';

const UtilityService = {
  buildProductMap: function (menu) {
    const productMap = {};

    if (menu && menu.categories) {
      menu.categories.forEach((category) => {
        if (category.groups) {
          category.groups.forEach((group) => {
            if (group.items) {
              group.items.forEach((item) => {
                productMap[item.catentry_id] = item.product;
              });
            }
          });
        }
      });
    }
    return productMap;
  },

  getProductImageURL: function (product, displayDefaultImageEnabled = false) {
    let url = null;
    if (product && product.media) {
      product.media.forEach((m) => {
        if (parseInt(m.is_defauld) === 1 && m.path) {
          url = m.path;
        }
      });
      if (url === null && product.media.length > 0) {
        url = product.media[0].path;
      }
    }
    if (url && !url.startsWith('http')) {
      url = MEDIA_BASE_URL + url;
    }
    if (url === null && displayDefaultImageEnabled) {
      url = '/assets/icon/default-product-image.svg';
    }
    return url;
  },

  getCategoryImageURL: function (category) {
    let url = '/assets/icon/main-dishes.svg';
    if (category?.thumbnail) {
      url = category.thumbnail;

      if (url && !url.startsWith('http')) {
        url = MEDIA_BASE_URL + url;
      }
    }
    return url;
  },

  isAllowToDineIn: function (product) {
    let allow = false;
    if (product.type === 'SIMPLE_PRODUCT' || product.type === 'ITEM') {
      if (product.attribute) {
        product.attribute.forEach((attr) => {
          if (attr.identifier === 'ALLOW_DINE_IN' && attr.value && attr.value.value === 'true') {
            allow = true;
          }
        });
      }
    } else if (product.type === 'COMPLEX_PRODUCT') {
      product.items.forEach((item) => {
        if (item.attribute) {
          item.attribute.forEach((attr) => {
            if (attr.identifier === 'ALLOW_DINE_IN' && attr.value && attr.value.value === 'true') {
              allow = true;
            }
          });
        }
      });
    }
    return allow;
  },
  isValidMobileNumber: function (mobile) {
    // Regular expression to match exactly 10 digits
    const regex = /^05\d{8}$/;
    return regex.test(mobile);
  },
};

export default UtilityService;
