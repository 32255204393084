import React from 'react';
import { Helmet } from 'react-helmet';

function CustomHelmet({ lang, direction, favicon, name, description }) {
  return (
    <Helmet
      htmlAttributes={{
        lang: lang,
        dir: direction,
      }}>
      <link rel="icon" href={favicon} />
      <title>{name || 'Get Menu'}</title>
      <meta name="description" content={description ? description : name} />
    </Helmet>
  );
}

export default CustomHelmet;
