import { KEYS } from '../utils/constant';
import { useSelector } from 'react-redux';

function useCart() {
  // Access the Redux state
  const menu = useSelector((state) => state.menu);
  const qrcode = useSelector((state) => state.qrcode);
  const shoppingCart = useSelector((state) => state.shoppingCart);

  const allowToOrder = resolveAllowToOrder(qrcode, menu);
  const autoCartOpen = qrcode?.settings?.AUTO_CART_OPEN === 'true';
  const miniCartButtonEanbled = menu?.settings?.MINI_CART_BUTTON_ENABLED === 'true';
  const couponEnabled = qrcode?.settings?.couponEnabled === 'true';
  const showTableSelectionModalOnFirstItem = qrcode?.settings?.SHOW_TABLE_SELECTION_MODAL_ON_FIRST_ITEM === 'true';
  const addItemNoteEnabled = qrcode?.settings?.ADD_ITEM_NOTE_ENABLED === 'true';

  const diningOption = sessionStorage.getItem(KEYS.DINING_OPTION);

  return {
    allowToOrder,
    autoCartOpen,
    showTableSelectionModalOnFirstItem,
    addItemNoteEnabled,
    miniCartButtonEanbled,
    shoppingCart,
    diningOption,
    couponEnabled,
    items: shoppingCart?.items,
    totalQuantity: shoppingCart?.total_quantity || 0,
    totalItems: shoppingCart?.total_items || 0,
    isEmpty: !shoppingCart || !shoppingCart.items || shoppingCart.items.length === 0,
  };
}

export default useCart;

/**
 * Allow to order is calculated base on:
 *  1. Default value is false
 *  2. Menu / QR Code Settings
 *  4. Session Storage
 * @param {*} qrcode
 * @param {*} menu
 * @returns True if allow to order is enabled. otherwise return false
 */
function resolveAllowToOrder(qrcode) {
  let allowToOrder = false;

  if (qrcode?.settings?.ALLOW_TO_ORDER === 'true') {
    allowToOrder = true;
  }

  if (sessionStorage.getItem(KEYS.ALLOW_TO_ORDER) === 'true') {
    allowToOrder = true;
  } else if (sessionStorage.getItem(KEYS.ALLOW_TO_ORDER) === 'false') {
    allowToOrder = false;
  }

  return allowToOrder;
}
