import { default as React } from 'react';
import { Route, Routes } from 'react-router-dom';
import { OrderConfirmation, SinglePageCheckout } from '../template/Checkout';
import M24LayoutTemplate from '../template/M24Layout';

function SinglePageLayout() {
  return (
    <Routes>
      <Route index element={<M24LayoutTemplate />} />
      <Route path="category/:categoryId" element={<M24LayoutTemplate />} />
      <Route path="checkout" element={<SinglePageCheckout containerClassName="m24-lauout" />} />
      <Route path="confirmation" element={<OrderConfirmation containerClassName="m24-lauout" />} />
    </Routes>
  );
}

export default SinglePageLayout;
