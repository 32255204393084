import { bindActionCreators } from '@reduxjs/toolkit';
import React from 'react';
import { connect } from 'react-redux';
import { fetchShoppingCartDetails } from '../../actions/shoppingCartActions';

function OrderItemAttributes(props) {
  const { item, menu } = props;

  const prod = menu?.products?.find((p) => parseInt(item.productId) === parseInt(p.id));
  const selectedItem = prod?.items?.find((a) => parseInt(a.id) === parseInt(item.catentry_id));

  return (
    <div className="order-item-attributes">
      {prod &&
        prod.attribute &&
        prod.attribute
          .filter((attr) => attr.usage === '1')
          .map((attr) => {
            const selectedValues = selectedItem?.attribute.find((a) => a['attribute_' + attr.id]);

            const value = attr.values.find(
              (v) => selectedValues && parseInt(v.attribute_value_id) === parseInt(selectedValues['attribute_' + attr.id])
            );
            return (
              <div key={attr.id} className="order-item-attribute">
                <p>
                  <span className="order-item-attribute-title">{attr.name}:</span> {value?.value}
                </p>
              </div>
            );
          })}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    shoppingCart: state.shoppingCart,
    qrcode: state.qrcode,
    menu: state.menu,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchShoppingCartDetails: fetchShoppingCartDetails,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderItemAttributes);
