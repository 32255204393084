import React from 'react';

const ItemDisplay = ({ item }) => {
  const containerStyles = {
    textAlign: 'center',
    padding: '10px',
  };

  const imageStyles = {
    width: 75,
  };

  const itemsStyle = {
    fontSize: 10,
    fontWeight: 600,
  };

  return (
    <div style={containerStyles} product-id={item.id}>
      <img src={item.thumbnail} alt={item.name} style={imageStyles} />
      <div style={itemsStyle}>{item.name}</div>
    </div>
  );
};

export default ItemDisplay;
