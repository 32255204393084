import React from 'react';
import { Product } from './Product';

const KidsGroup = ({ products }) => {
  return (
    <div>
      <div className="product-list">
        <p className="title" style={{ marginBottom: 10 }}>
          Milkshake
        </p>
        {products
          .filter((p) => ['MA1171', 'MA1172', 'MA1173', 'MA1174', 'MA1175', 'MA1176', 'MA1177'].indexOf(p.partnumber) !== -1)
          .map((product, index) => (
            <Product key={index} product={product} />
          ))}
      </div>

      <div className="product-list">
        <p className="title" style={{ marginBottom: 10 }}>
          Sweets
        </p>
        {products
          .filter((p) => ['MA1178', 'MA1179', 'MA1180', 'MA1181', 'MA1182', 'MA1183'].indexOf(p.partnumber) !== -1)
          .map((product, index) => (
            <Product key={index} product={product} />
          ))}
      </div>
    </div>
  );
};

export default KidsGroup;
