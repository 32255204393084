import React from 'react';
import { useTranslation } from 'react-i18next';
import { useProSidebar } from 'react-pro-sidebar';

function ShoppingCartHeader() {
  const { t } = useTranslation();
  const { collapseSidebar } = useProSidebar();

  return (
    <div className="mini-cart-header">
      <h4>{t('Shopping Cart')}</h4>
      <span className="mini-cart-close" onClick={() => collapseSidebar()}>
        <img src="/assets/icon/close.svg" alt={t('Close')} />
      </span>
    </div>
  );
}

export default ShoppingCartHeader;
