import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import StarRatings from 'react-star-ratings';
import AddToCart from '../../components/AddToCart';
import FooterNavigation from '../../components/FooterNavigation';
import Header from '../../components/Header';
import MerchandisingAssociations from '../../components/ProductDetails/MerchandisingAssociations';
import ProductDefiningAttribute from '../../components/ProductDefiningAttribute';
import ProductFullImage from '../../components/ProductFullImage';
import ProductPrice from '../../components/ProductPrice';
import ProductReviews from '../../components/ProductReviews';
import useCart from '../../hooks/useCart';
import useMenu from '../../hooks/useMenu';
import useProduct from '../../hooks/useProduct';
import useQRCode from '../../hooks/useQRCode';
import MenuService from '../../services/menu';
import ProductDescription from '../../components/ProductCard/ProductDescription';
import ProductRate from '../../components/ProductRate';

function ProductDetailsPage(props) {
  const { t } = useTranslation();
  const { reviewEnabled, rateEnabled } = useMenu();
  const { uuid, footerNavigationEnabled } = useQRCode();
  const { allowToOrder } = useCart();
  const { availableItems, type, items, id, name, description, rate, merchandisingAssociations, media, total_view } = useProduct();

  const [selectedAttributes, setSelectedAttributes] = useState([]);
  const [selectedAssociations, setSelectedAssociations] = useState([]);

  const resolveSelectedProductId = () => {
    if (type === 'COMPLEX_PRODUCT') {
      if (availableItems.length === 1) {
        return availableItems[0].id;
      }
      let filterItems = items;
      selectedAttributes.forEach((element) => {
        filterItems = filterItems?.filter((i) => {
          for (let x = 0; x < i.attribute.length; x++) {
            if (i.attribute[x]['attribute_' + element.attributeId] == element.selectedValueId) {
              return true;
            }
          }
        });
      });

      if (selectedAttributes.length > 0 && filterItems.length > 0) {
        return filterItems[0].id;
      }

      return null;
    }
    return id;
  };

  useEffect(() => {
    if (id) {
      MenuService.sendTrackEvent('PRODUCT_VIEW', { productId: id });
      window.scrollTo(0, 0);
    }
  }, [id]);

  return (
    <div className="two-page-layout product-details-page main-card">
      <Header showBackButton={true} showShareButton={false} showBusinessDetails={false} backURL={'/m/' + uuid} />

      <ProductFullImage productId={id} media={media} />

      <div className="product-details">
        <div className="product-title">
          <p>{name}</p>
        </div>

        {/* Product Rating & Reviews*/}
        {rateEnabled && <ProductRate rate={rate} totalView={total_view} />}

        {/* Product Price*/}
        <div className="product-price">
          <div className="product-offer-price">
            <ProductPrice selectedItemId={resolveSelectedProductId()} productId={id} />
          </div>
          <p className="product-list-price"></p>
        </div>

        {/* Product Description*/}
        {description && <ProductDescription description={description} title={t('Details') + ':'}></ProductDescription>}

        {type === 'COMPLEX_PRODUCT' && availableItems.length > 1 && (
          <ProductDefiningAttribute onChange={setSelectedAttributes} productId={id} />
        )}

        {/* Product Merchandising Associations*/}
        <MerchandisingAssociations onChange={setSelectedAssociations} productId={id} />

        {allowToOrder && (
          <div className="product-actions">
            <AddToCart productId={resolveSelectedProductId()} showNote={true} merchandisingAssociations={selectedAssociations} />
          </div>
        )}

        {reviewEnabled && <ProductReviews productId={id} />}
      </div>

      {footerNavigationEnabled && <FooterNavigation />}
    </div>
  );
}

export default ProductDetailsPage;
