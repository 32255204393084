import React, { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useNavigate } from 'react-router-dom';
import useDesign from '../../hooks/useDesign';
import useQRCode from '../../hooks/useQRCode';

function ProductThumbnail({ url, productId }) {
  const [loaded, setLoaded] = useState(false);
  const { pdpEnabled } = useDesign();

  const navigate = useNavigate();
  const { uuid } = useQRCode();

  const navigateToProduct = () => {
    navigate(`/m/${uuid}/product/${productId}`);
  };

  return (
    <>
      {url && (
        <div className="product-thumbnail">
          <div className="product-thumbnail-wrapper">
            {pdpEnabled && (
              <div onClick={navigateToProduct}>
                {!loaded && <Skeleton borderRadius={20} height={140} />}
                <img alt="" style={{ display: loaded ? 'block' : 'none' }} src={url} onLoad={() => setLoaded(true)} />
              </div>
            )}
            {!pdpEnabled && (
              <>
                {!loaded && <Skeleton borderRadius={20} height={140} />}
                <img alt="" style={{ display: loaded ? 'block' : 'none' }} src={url} onLoad={() => setLoaded(true)} />
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default ProductThumbnail;
