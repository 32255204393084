import React from 'react';
import { useTranslation } from 'react-i18next';

function Coupon() {
  const { t } = useTranslation();

  return (
    <div className="mini-cart-coupon">
      <input type="text" placeholder={t('Coupon Code')} />
      <button>{t('Apply Code')}</button>
    </div>
  );
}

export default Coupon;
