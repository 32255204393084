import React from 'react';
import useConfig from '../../hooks/useConfig';
import useMenu from '../../hooks/useMenu';
import UtilityService from '../../services/utility';
import ProductList from '../ProductList';

function GroupDetails({ group }) {
  const { getProductsInfoByIds, dineInExtraCostEnabled } = useMenu();
  const { dineInOrTakeaway } = useConfig();

  let products = getProductsInfoByIds(group.items);

  if (!!dineInExtraCostEnabled && dineInOrTakeaway === 'DINE_IN') {
    products = products.filter((p) => UtilityService.isAllowToDineIn(p));
  }

  // make sure product are sorted on same way in group
  products = products.sort((a, b) => group.items.indexOf(a.id) - group.items.indexOf(b.id));

  return (
    <div className="menu-group" key={group.id} data-group-id={group.id}>
      {group.name && <div className="group-title">{group.name}</div>}

      {group.description && <div className="group-description">{group.description}</div>}

      {products && products.length > 0 && <div className="menu-group-items">{products && <ProductList products={products} />}</div>}
    </div>
  );
}

export default GroupDetails;
