import { useSelector, useDispatch } from 'react-redux';

function useConfig() {
  // Access the Redux state
  const config = useSelector((state) => state.config);

  return { ...config };
}

export default useConfig;
