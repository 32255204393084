import React from 'react';

function ProductProperties({ properties }) {
  return (
    <div className="product-properties">
      {properties &&
        properties.map((property, index) => {
          return (
            <div className="product-property" key={index}>
              <img alt="" className="product-property-icon" src={property.icon} />
              <span className="product-property-name">{property.name}</span>
            </div>
          );
        })}
    </div>
  );
}

export default ProductProperties;
