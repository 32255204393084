import React from 'react';
import { useTranslation } from 'react-i18next';
import useCart from '../../../hooks/useCart';
import UtilityService from '../../../services/utility';
import ProductPrice from './../../ProductPrice';
import useMenu from '../../../hooks/useMenu';

function AssociationsList({ type, title, list, onSelect, isSelected }) {
  const { t } = useTranslation();
  const { allowToOrder } = useCart();
  const { getProductById } = useMenu();

  if (!list || list.length === 0) {
    return null;
  }

  return (
    <div className={`merchandising-associations-${type.toLowerCase()}`}>
      <p className="merchandising-associations-title">{title}:</p>
      <div className="merchandising-associations-list">
        {list.map((item, index) => {
          const product = getProductById(item.productId);
          return (
            <div key={index} className="merchandising-associations-item">
              {product.media && <img src={UtilityService.getProductImageURL(product)} alt="" />}

              <p className="name">
                {item.type === 'WITHOUT' && product.partnumber !== 'FX153' ? t('without') + ' ' : ''}
                {product?.name}
              </p>

              {item.hidePrice != '1' && (
                <div className="price">
                  <ProductPrice productId={item.productId} />
                </div>
              )}

              {allowToOrder && (
                <input
                  type="checkbox"
                  className={`checkbox ${item.hidePrice === '1' ? 'no-price' : ''}`}
                  checked={isSelected(item.productId)}
                  onChange={() => onSelect(item.productId)}
                />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default AssociationsList;
