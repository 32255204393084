import React from 'react';
import { Product } from './Product';

const HotDrinksGroup = ({ products }) => {
  return (
    <div>
      <div className="product-list">
        {products
          .filter((p) => ['MA1146', 'MA1147', 'MA1142', 'MA1143', 'MA1145'].indexOf(p.partnumber) === -1)
          .map((product, index) => (
            <Product key={index} product={product} />
          ))}
      </div>

      <div className="product-list">
        {products
          .filter((p) => ['MA1142', 'MA1143', 'MA1145'].indexOf(p.partnumber) !== -1)
          .map((product, index) => (
            <Product key={index} product={product} />
          ))}
      </div>

      <div className="product-list">
        {products
          .filter((p) => ['MA1146', 'MA1147'].indexOf(p.partnumber) !== -1)
          .map((product, index) => (
            <Product key={index} product={product} />
          ))}
      </div>
    </div>
  );
};

export default HotDrinksGroup;
