import React from 'react';
import { useTranslation } from 'react-i18next';
import useCheckout from '../../hooks/useCheckout';

function CustomerInfo(props) {
  const { t } = useTranslation();
  const { saveCustomerInfo, customerName, customerMobile } = useCheckout();

  return (
    <div className="order-customer-info">
      <div>
        <p className="order-customer-info-title">{t('Name')}:</p>
        <input placeholder={t('Name')} value={customerName} onChange={(e) => saveCustomerInfo('name', e.target.value)} />
      </div>

      <div>
        <p className="order-customer-info-title">{t('Mobile')}:</p>
        <input placeholder={t('Mobile')} value={customerMobile} onChange={(e) => saveCustomerInfo('mobile', e.target.value)} />
      </div>
    </div>
  );
}

export default CustomerInfo;
