import React from 'react';
import ShoppingCartItem from './ShoppingCartItem';
import useCart from '../../hooks/useCart';
import { useTranslation } from 'react-i18next';
import ShoppingCartEmpty from './ShoppingCartEmpty';

function ShoppingCartDetails() {
  const { items, isEmpty } = useCart();

  const { t } = useTranslation();

  return (
    <div className="mini-cart-details">
      {!isEmpty && (
        <div className="mini-cart-items">
          {items?.map((item) => {
            return <ShoppingCartItem key={item.id} item={item} />;
          })}
        </div>
      )}
      {isEmpty && <ShoppingCartEmpty />}
    </div>
  );
}

export default ShoppingCartDetails;
