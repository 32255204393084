import { useSelector, useDispatch } from 'react-redux';
import { resetShoppingCartCart, setShoppingCartCustomerMobile, setShoppingCartCustomerName } from '../actions/shoppingCartActions';
import ShoppingCartService from '../services/shoppingCart';
import UtilityService from '../services/utility';

function useCheckout() {
  // Access the Redux state
  const qrcode = useSelector((state) => state.qrcode);
  const shoppingCart = useSelector((state) => state.shoppingCart);
  const dispatch = useDispatch();

  const customerFormEnabled = qrcode?.settings?.SHOW_CUSTOMER_FORM_IN_CHECKOUT === 'true';
  const tableSelectionEnabled = qrcode?.settings?.SHOW_TABLE_SELECTOR_IN_CHECKOUT === 'true';
  const printCartEnabled = qrcode?.settings?.SHOW_PRINT_CART_BUTTON_IN_CHECKOUT === 'true';
  const otpEnabled = qrcode?.settings?.OTP_ENABLED === 'true';

  const customerName = shoppingCart?.customerName;
  const customerMobile = shoppingCart?.customerMobile;
  const tableNumber = shoppingCart?.tableNumber;
  const orderComments = shoppingCart?.comments;

  const saveCustomerInfo = (filed, value) => {
    switch (filed) {
      case 'name':
        dispatch(setShoppingCartCustomerName(value));
        break;
      case 'mobile':
        dispatch(setShoppingCartCustomerMobile(value));
        break;
      default:
        break;
    }
  };

  const isValidForm = () => {
    let isValid = true;
    if (tableSelectionEnabled && (!tableNumber || parseInt(tableNumber) <= 0)) {
      isValid = false;
    }
    if (customerFormEnabled) {
      if (!customerName || !customerMobile) {
        isValid = false;
      }
      if (!UtilityService.isValidMobileNumber(customerMobile)) {
        isValid = false;
      }
    }

    return isValid;
  };

  const submitOrder = (onSuccess, onError) => {
    const data = {
      comments: orderComments,
    };

    if (customerFormEnabled) {
      data.customerName = customerName;
      data.customerMobile = customerMobile;
    }

    if (tableSelectionEnabled) {
      data.tableNumber = tableNumber;
    }

    ShoppingCartService.submitCart(qrcode.id, data)
      .then((response) => {
        if (response.ok) {
          dispatch(resetShoppingCartCart());
          if (onSuccess) {
            onSuccess();
          }
        } else {
          if (onError) {
            onError();
          }
        }
      })
      .catch((error) => {
        if (onError) {
          onError();
        }
      });
  };

  return {
    customerFormEnabled,
    tableSelectionEnabled,
    printCartEnabled,
    otpEnabled,
    customerName: customerName || '',
    customerMobile: customerMobile || '',
    tableNumber: tableNumber,
    orderComments: orderComments || '',

    saveCustomerInfo,
    isValidForm,
    submitOrder,
  };
}

export default useCheckout;
